<!--
 * @Author: huadan
 * @Date: 2021-04-20 13:33:09
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-06-22 19:29:01
 * @Description: 导出结算单
-->

<style lang="less" scoped>
.pdf-payment {
  background-color: #fff;
  min-height: 100%;
  min-width: min-content;
}
</style>

<template>
  <div class="pdf-payment">
    <PaymentInfo :isPdf="true" :ids="$route.query.ids ? $route.query.ids.split(',') : []" />
  </div>
</template>

<script>
import withPdfPreconditions from '@/mixins/withPdfPreconditions'
import PaymentInfo from '../../modules/payment/component/PaymentInfo'

export default {
  name: 'PdfPayment',
  mixins: [withPdfPreconditions],
  components: {
    PaymentInfo,
  },
}
</script>
