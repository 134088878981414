/*
 * @Author: gaojingran
 * @Date: 2021-05-14 12:34:17
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-19 12:25:37
 * @Description: pdf 导出补全字典表请求
 */
import Cookies from 'js-cookie'
import withAsync from './withAsync'

export default {
  mixins: [withAsync],
  created() {
    const { tk } = this.$route.query
    Cookies.set(window.$g.storageKey.TOKEN, tk)
  },
  mounted() {
    Promise.all([
      this.$http('dict'),
      this.$http('permissions', {
        params: { system: 2 },
      }),
      this.$http('userInfo'),
      this.$http('sysLangInfoList'),
      this.$http('dictTree'),
    ]).then(([dict, permission, userInfo, langList, treeAll]) => {
      // 行业领域
      const industry = treeAll.find((v) => v.dictValue === '0')?.children || []
      // vuex
      this.$store.commit('app/update', {
        userInfo: userInfo,
        dictList: dict,
        permissions: permission,
        langList: langList,
        industryList: industry.map((v) => {
          const shake = (arr) => {
            return arr.map((c) => ({
              ...c,
              children: c.children.length ? shake(c.children) : undefined,
            }))
          }

          return {
            ...v,
            children: shake(v.children),
          }
        }),
      })
      this.initFinish = true
    })
  },
}
